import { galleryKey } from '../navigate'
import { useNavigateNoScroll } from './useNavigateNoScroll'
import React from 'react'
import { GALLERY } from '../../urls'
import { useLocation } from '@reach/router'

export const useGalleryNavigation = (key = galleryKey) => {
    const navigate = useNavigateNoScroll()
    const location = useLocation()

    return React.useMemo(() => {
        return {
            goTo: (id: string | null) => {
                if (id) {
                    const params = new URLSearchParams(location.search)
                    params.set(key, id)
                    return navigate(GALLERY + '?' + params.toString())
                } else {
                    const params = new URLSearchParams(location.search)
                    params.delete(key)
                    const _params = params.toString()
                    return navigate(GALLERY + (_params ? '?' + _params : ''))
                }
            },
        }
    }, [navigate])
}
