import { navigate as rrNavigate, NavigateOptions } from '@reach/router'

export const wrapNavigateNoScroll = (
    fn: (to: string, options?: NavigateOptions<{}> | undefined) => Promise<void>,
) => {
    return ((l, s) =>
        fn(l, {
            ...s,
            state: {
                ...s?.state,
                disableScrollUpdate: true,
            },
        })) as (to: string, options?: NavigateOptions<{}>) => Promise<void>
}

export const navigateNoScroll = wrapNavigateNoScroll(rrNavigate)

export const galleryKey = 'galleryId'
