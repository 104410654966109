import { GalleryImage } from '../../../molecules/ClientReview'
import { useLocation } from '@reach/router'
import React from 'react'
import { navigateNoScroll } from '../../../utils/navigate'
import { useGalleryNavigation } from '../../../utils/hooks/useGalleryNavigation'

export const useGallery = (gallery: GalleryImage[]) => {
    const location = useLocation()
    const targetIndex = React.useMemo(() => {
        const params = new URLSearchParams(location.search)
        return gallery.findIndex((x) => {
            return x.src === params.get('galleryId')
        })
    }, [location.search, gallery])
    const target = gallery[targetIndex]
    const { goTo } = useGalleryNavigation()
    const push = async (id: string) => {
        await goTo(id)
    }

    const open = async (image: GalleryImage) => {
        await push(image.src)
    }

    const canPrev = targetIndex > 0
    const canNext = targetIndex > -1 && targetIndex < gallery.length - 1
    return {
        target,
        targetIndex,
        canPrev,
        canNext,
        preview(size: number) {
            if (targetIndex < 0) return []
            const leftIndex = Math.max(0, targetIndex - Math.floor(size / 2))
            return gallery.slice(leftIndex, leftIndex + size)
        },
        async close() {
            await goTo(null)
        },
        open,
        async next() {
            if (!canNext) return
            return open(gallery[targetIndex + 1])
        },
        async prev() {
            if (!canPrev) return
            return open(gallery[targetIndex - 1])
        },
    }
}
