import React from 'react'
import { useGallery } from './hooks/useGallery'
import { GalleryImage } from '../../molecules/ClientReview'
import { Project } from '../../molecules/GalleryMenu'

export type GalleryContext = {
    filters: {
        constructionTypes: string[]
        projects: string[]
        setConstructionType(name: string): void
        setProject(name: string): void
    }
    gallery: GalleryImage[]
    filteredGallery: GalleryImage[]
    projects: Project[]
    navigation: ReturnType<typeof useGallery>
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const GalleryCxt = React.createContext<GalleryContext>(null)
