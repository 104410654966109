import React from 'react'
import { GalleryContext, GalleryCxt } from './context'
import { useGallery } from './hooks/useGallery'
import { GalleryImage } from '../../molecules/ClientReview'
import { useProjectNavigation } from './hooks/useProjectNavigation'

const gImg = (g: GalleryImage) => g

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const cxt = require.context(`../../images/projects/`, true, /\.(png|jpe?g|svg)$/)
export const galleryByProjects = cxt.keys().reduce((acc: any, path: string) => {
    const name = path.replace(/^\.?\/?/, '').split('/')[0]
    if (!(name in acc)) {
        acc[name] = [cxt(path).default]
    } else {
        acc[name].push(cxt(path).default)
    }
    return acc
}, {})

export const projectsObj = Object.keys(galleryByProjects).map((name) => ({ name }))

export const gallery: GalleryImage[] = Object.entries(galleryByProjects)
    .map(([name, gal]) => {
        return (gal as string[]).map((img, i) => {
            return gImg({
                categories: [],
                src: img,
                imageName: `${name} / ${i + 1}`,
                projectName: name,
            })
        })
    })
    .flat(1)

export type GalleryProviderProps = {}

export const GalleryProvider: React.FC<GalleryProviderProps> =
    React.memo<GalleryProviderProps>(({ children }) => {
        const { projects, toggle: toggleProject } = useProjectNavigation()
        const [constructionTypes, setConstructionTypes] = React.useState<
            GalleryContext['filters']['constructionTypes']
        >([])

        const filteredGallery = React.useMemo(() => {
            return gallery.filter((item) => {
                return (
                    (projects.length === 0 || projects.includes(item.projectName)) &&
                    // I don't know if construction types are the same as categories...
                    (constructionTypes.length === 0 ||
                        item.categories.every((cat) => {
                            return constructionTypes.includes(cat.name)
                        }))
                )
            })
        }, [projects, constructionTypes])
        const navigation = useGallery(filteredGallery)
        return (
            <GalleryCxt.Provider
                value={{
                    projects: projectsObj,
                    filteredGallery,
                    gallery,
                    navigation,
                    filters: {
                        projects,
                        constructionTypes,
                        setProject(name: string) {
                            toggleProject(name)
                        },
                        setConstructionType(name: string) {
                            setConstructionTypes((p) => (p.includes(name) ? [] : [name]))
                        },
                    },
                }}
            >
                {children}
            </GalleryCxt.Provider>
        )
    })

GalleryProvider.displayName = 'GalleryProvider'

export default GalleryProvider
