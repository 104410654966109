import { useNavigateNoScroll } from '../../../utils/hooks/useNavigateNoScroll'
import { GALLERY } from '../../../urls'
import { useLocation } from '@reach/router'
import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

const projectsKey = 'projects'
export const useProjectNavigation = () => {
    const location = useLocation()
    const { navigate: navigateWScroll } = useI18next()

    const navigate = useNavigateNoScroll()
    const projects = React.useMemo(() => {
        try {
            return JSON.parse(
                new URLSearchParams(location.search).get(projectsKey) ?? '[]',
            ) as string[]
        } catch (e) {
            return []
        }
    }, [location.search])
    return {
        projects,
        toggle: (proyName: string, scrollTop?: boolean) => {
            const params = new URLSearchParams(window.location.search)
            const _projects = JSON.parse(params.get(projectsKey) ?? '[]') as string[]
            params.set(
                projectsKey,
                JSON.stringify(_projects.includes(proyName) ? [] : [proyName]),
            )
            const _navigate = scrollTop ? navigateWScroll : navigate
            return _navigate(GALLERY + '?' + params.toString())
        },
    }
}
